
import { defineComponent } from 'vue'
import { formatMoney } from '@/libs/utils'
export default defineComponent({
  props: {
    from: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    },
    amount: {
      type: String,
      default: ''
    },
    symbol: {
      type: String,
      default: ''
    }
  },
  setup () {
    const formatInfo = (info: string) => {
      return info.slice(0, 16) + '...'
    }
    return {
      formatMoney,
      formatInfo
    }
  }
})
