<template>
  <Loading v-if="loading" />
  <NotFound v-else-if="notFound" type="tx" :value="everHash" />
  <div v-else class="bg-scanMain pt-20 pb-12 lg:pt-28">
    <Search class="flex justify-center pb-4 px-6 lg:hidden" />
    <div class="max-w-1200px  bg-white xl:mx-auto rounded-2xl sm:p-20 px-4 py-2 mx-4" style="box-shadow: 4px 4px 50px rgba(244, 239, 244, 0.5)">
      <LabelItem label="everHash">
        <div class="break-all flex-1 flex">
          {{ everpayTx.everHash }}
          <img src="@/images/copy.svg" class="cursor-pointer ml-1 clipboard-everHash sm:block hidden" :data-clipboard-text="everpayTx.everHash">
        </div>
      </LabelItem>
      <LabelItem :label="t('type')">
        <div>{{ everpayTx.action === 'burn' ? 'Withdraw' : (everpayTx.action === 'mint' ? 'Deposit' : capitalize(everpayTx.action)) }}</div>
      </LabelItem>
      <LabelItem :label="t('status')">
        <div>{{ capitalize(everpayTx.status) }}</div>
      </LabelItem>
      <LabelItem :label="t('rollup_tx')" class="break-all">
        <div v-if="everpayTx.id" class="flex flex-1 items-center">
          <router-link class="text-scanMainColor hover:text-scanHoverColor" :to="`/arId/${everpayTx.id}`">
            {{ everpayTx.id }}
          </router-link>
          <img src="@/images/copy.svg" class="cursor-pointer ml-1 clipboard-rolluptx sm:block hidden" :data-clipboard-text="everpayTx.id">
        </div>
        <img v-else class="w-6 h-6 animate-spin-slow" :src="require('../images/loading.svg')">
      </LabelItem>
      <LabelItem v-if="everpayTx.id && everpayTx.itemID" :label="t('arId')" class="break-all">
        <div v-if="everpayTx.itemID" class="flex flex-1 items-center">
          <a :href="`https://viewblock.io/arweave/tx/${everpayTx.itemID}`" target="_blank" class="text-scanMainColor hover:text-scanHoverColor">
            {{ everpayTx.itemID }}
          </a>
          <img src="@/images/copy.svg" class="cursor-pointer ml-1 clipboard-arId sm:block hidden" :data-clipboard-text="everpayTx.itemID">
        </div>
        <img v-else class="w-6 h-6 animate-spin-slow" :src="require('../images/loading.svg')">
      </LabelItem>
      <LabelItem :label="t('date_time')" :no-border="true">
        <div>{{ everpayTx.timestampFormat }}</div>
      </LabelItem>
      <TxLine />
      <LabelItem :label="t('from')">
        <div class="flex-1 flex">
          <router-link class="text-scanMainColor hover:text-scanHoverColor break-all flex sm:items-center items-start " :to="`/account/${everpayTx.from}`">
            <img class="w-4 h-4 mt-0.5 sm:mt-0 mr-1" :src="require('../images/everPay-symbol.svg')">
            <span>{{ everpayTx.from }}</span>
          </router-link>
          <img src="@/images/copy.svg" class="cursor-pointer ml-1  clipboard-from sm:block hidden" :data-clipboard-text="everpayTx.from">
        </div>
      </LabelItem>
      <LabelItem :label="t('to')">
        <div class="flex-1 flex relative">
          <!-- <img v-if="ansLoading" class="w-6 h-6 animate-spin-slow" :src="require('../images/loading.svg')"> -->
          <router-link v-if="ansAddress && everpayTx.action !== 'burn'" :to="`/account/${everpayTx.to}`">
            <MoreInfo>
              <template #info>
                <div class="text-scanMainColor hover:text-scanHoverColor flex items-center">
                  <img
                    class="w-4 h-4 mr-1"
                    :src="require('../images/ans.png')">
                  <span>{{ ansAddress }}</span>
                </div>
              </template>
              {{ everpayTx.to }}
            </MoreInfo>
          </router-link>
          <router-link v-else-if="everpayTx.action !== 'burn'" class="text-scanMainColor hover:text-scanHoverColor break-all flex sm:items-center items-start" :to="`/account/${everpayTx.to}`">
            <img
              class="w-4 h-4 mt-0.5 sm:mt-0 mr-1 "
              :src="require('../images/everPay-symbol.svg')">
            <span>{{ everpayTx.to }} <span v-if="everpayTx.pubName" style="color:rgba(13, 13, 13, 0.65);" class="whitespace-nowrap">({{ t(everpayTx.oprationType) }}: {{ everpayTx.pubName }})</span></span>
          </router-link>
          <a
            v-else
            class="text-scanMainColor hover:text-scanHoverColor break-all flex sm:items-center items-start"
            :href="everpayTx.burnToUrl"
            target="_blank">
            <ChainTypeLogo class="mr-1 mt-0.5 sm:mt-0 w-4 h-4" :target-chain-type="everpayTx.targetChainType" />
            <span>{{ everpayTx.to }} <span v-if="everpayTx.pubName" style="color:rgba(13, 13, 13, 0.65);" class="whitespace-nowrap">({{ t(everpayTx.oprationType) }}: {{ everpayTx.pubName }})</span></span>
          </a>
          <img
            src="@/images/copy.svg"
            class="cursor-pointer ml-1 clipboard-to sm:block hidden"
            :data-clipboard-text="ansAddress ? ansAddress : everpayTx.to">
        </div>
      </LabelItem>
      <LabelItem :label="t('amount')">
        <div>{{ formatMoney(everpayTx.amountFormat) }} {{ everpayTx.tokenSymbol.toUpperCase() }}</div>
      </LabelItem>
      <LabelItem :label="t('tx_fee')" :no-border="true">
        <div>{{ formatMoney(everpayTx.feeFormat) }} {{ everpayTx.tokenSymbol.toUpperCase() }}</div>
      </LabelItem>
      <TxLine />
      <LabelItem :label="t('internal_status')" :no-border="true">
        <div
          class=" flex flex-1"
        >
          <div class="flex px-3 py-1 rounded-md cursor-pointer" :class="isInternalSuccess ? 'bg-bgSuccess text-txSuccess' : 'bg-bgError text-txError'">
            <el-icon v-if="isInternalSuccess" class="mr-1 mt-1" style="font-size:16px;">
              <circle-check-filled />
            </el-icon>
            <el-icon v-else class="mr-1 mt-1 text-base" style="font-size:16px;">
              <circle-close-filled />
            </el-icon>
            <div class="break-all">
              {{ everpayTx.internalStatus }}
            </div>
          </div>
        </div>
      </LabelItem>
      <LabelItem
        v-if="everpayTx.internalTxs.length"
        :label="isInternalSuccess ? t('internal_txs') : t('internal_txs_failed')"
        :label-class="isInternalSuccess ? '' : 'text-everRed1'"
      >
        <div class="flex-1">
          <InternalItem
            v-for="(item, index) in everpayTx.internalTxs"
            :key="index"
            :symbol="item.symbol"
            :amount="item.amount"
            :from="item.from"
            :to="item.to" />
        </div>
      </LabelItem>
      <LabelItem v-if="everpayTx.action === 'burn' || everpayTx.targetChainTxHash" :label="t('target_chain_tx_hash')" :no-border="true">
        <div v-if="everpayTx.targetChainTxHash" class="flex flex-1">
          <a
            class="text-scanMainColor hover:text-scanHoverColor break-all flex sm:items-center items-start"
            :href="everpayTx.targetChainTxHashUrl"
            target="_blank">
            <ChainTypeLogo class="mr-1 mt-0.5 sm:mt-0 w-4 h-4" :target-chain-type="everpayTx.targetChainType" />
            <span>{{ everpayTx.targetChainTxHash }}</span>
          </a>
          <img src="@/images/copy.svg" class="cursor-pointer ml-1 clipboard-chainTxHash sm:block hidden" :data-clipboard-text="everpayTx.targetChainTxHash">
        </div>
        <img v-else class="w-6 h-6 animate-spin-slow" :src="require('../images/loading.svg')">
      </LabelItem>
      <TxLine v-if="manualSubmitVisible" />
      <LabelItem v-if="manualSubmitVisible" :label="t('manual_submission')" :no-border="true">
        <div
          class="h-9 w-165px cursor-pointer rounded-lg border"
          :class="submitSuccess ? 'border-txSuccess text-txSuccess' : 'border-scanMainColor  text-scanMainColor hover:border-scanHoverColor hover:text-scanHoverColor active:border-scanClick active:text-scanClick'"
          @click="submitSuccess ? '' : walletInfo.account && walletInfo.isNetworkMatch ? manualSubmission() : connectwallet()">
          <div class="flex items-center justify-center h-full">
            <el-icon v-if="submitSuccess" class="mr-1 text-txSuccess" style="font-size:16px;">
              <circle-check-filled />
            </el-icon>
            <img v-if="connectLoading || submitLoading" class="w-4 h-4 animate-spin-slow mr-1" :src="require('../images/loading.svg')"> {{ submitSuccess ? t('successfully_submitted') : connectLoading ? t('connecting') : submitLoading ? t('submitting') : !walletInfo.account ? t('connecting_wallet') : !walletInfo.isNetworkMatch ? t('switch_the_network') : t('submit') }}
          </div>
        </div>
      </LabelItem>
      <TxLine v-if="everpayTx.action === 'burn' || everpayTx.targetChainTxHash" />
      <LabelItem label="Nonce">
        <div>{{ everpayTx.nonce }}</div>
      </LabelItem>
      <LabelItem :label="t('version')" :no-border="everpayTx.data ? false : true">
        <div>{{ everpayTx.version }}</div>
      </LabelItem>
      <LabelItem v-if="everpayTx.data" :label="t('data')" :no-border="true">
        <div style="border: 1px solid rgba(0, 0, 0, 0.15);max-width:500px" class="rounded-lg flex-1 break-all pl-1.5 pr-0.5 py-1.5">
          <div class="scrollbarDate overflow-y-auto pr-1" style="max-height: 170px;max-width:500px">
            {{ everpayTx.data }}
          </div>
        </div>
      </LabelItem>
    </div>
  </div>
</template>

<script lang="ts">
import { watch, ref, Ref, computed, onMounted, defineComponent, reactive, onActivated } from 'vue'
import { useRoute } from 'vue-router'
import LabelItem from '@/components/LabelItem.vue'
import InternalItem from '@/components/InternalItem.vue'
import TxLine from '@/components/TxLine.vue'
import dayjs from 'dayjs'
import { getExplorerUrl, getInternalRecordsFromBundleTx, formatMoney, formatTargetChainType, formatTokenTag, handleErrorMsg, connectMetamask, sendTransaction } from '@/libs/utils'
import { fromDecimalToUnit } from '@/libs/everpay-js/utils/util'
import { useStore } from '@/store'
import { getTxByEverHash, getAnsResolver, getManualSubmitData, submitManualHash } from '@/libs/api'
import NotFound from '@/components/common/NotFound.vue'
import Loading from '@/components/common/Loading.vue'
import { useI18n } from 'vue-i18n'
import ClipboardJS from 'clipboard'
import { ElMessage } from 'element-plus'
import Search from '@/components/Search.vue'
import capitalize from 'lodash/capitalize'
import isString from 'lodash/isString'
import ChainTypeLogo from '@/components/ChainTypeLogo.vue'
import MoreInfo from '@/components/common/MoreInfo.vue'
import { ChainType } from '../libs/everpay-js/types'
import { ManualSubmitData } from '../libs/types'
import { checkNetwork } from '../libs/chainLibAdaptor/ethereum'
import { Web3Provider } from '@ethersproject/providers'
export default defineComponent({
  components: {
    LabelItem,
    InternalItem,
    TxLine,
    NotFound,
    Loading,
    Search,
    ChainTypeLogo,
    MoreInfo
  },
  setup () {
    const route = useRoute()
    const notFound = ref(false)
    const loading = ref(true)
    const store = useStore()
    const { t } = useI18n()
    const tokens = computed(() => store.state.tokenList)
    const manualBurnTokens = computed(() => store.state.manualBurnTokens)
    const connectLoading = ref(false)
    const submitLoading = ref(false)
    const submitSuccess = ref(false)
    const walletInfo = reactive({
      account: '',
      accChainType: '',
      isNetworkMatch: false
    })
    const everHash = computed(() => {
      return route.params.everHash as string
    })
    const ansLoading = ref(false)
    const ansAddress = ref('')
    const everpayTx: Ref<any> = ref(null as any)
    // 手动提交数据
    const manualSubmitData = ref<ManualSubmitData | null>(null)
    // 指定 burn 交易 targetChainTxHash 为空 和 指定代币时才会出现
    const manualSubmitVisible = computed(() => {
      if (everpayTx.value && manualSubmitData.value) {
        const { action, targetChainTxHash } = everpayTx.value
        const token = formatTokenTag(everpayTx.value, tokens.value)
        return action === 'burn' && targetChainTxHash === '' && manualBurnTokens.value.find((t) => t.tokenTag === token?.tag)
      }
      return false
    })
    const isInternalSuccess = ref(true)
    const isArweaveAddress = (address: string): boolean => {
      return isString(address) && address.length === 43 && address.search(/[a-z0-9A-Z_-]{43}/g) === 0
    }
    // 更新 account 检查 chainType 是否一致
    const updateWalletInfo = async () => {
      if (window.ethereum) {
        walletInfo.account = window.ethereum ? window.ethereum.selectedAddress : ''
        walletInfo.accChainType = window.ethereum ? window.ethereum.chainId : ''
        const provider = new Web3Provider(window.ethereum)
        const network = await provider.getNetwork()
        try {
          checkNetwork(network.chainId, manualSubmitData.value?.chainType as ChainType)
          walletInfo.isNetworkMatch = true
        } catch (e) {
          console.log(e)
          walletInfo.isNetworkMatch = false
        }
      }
    }
    const updateEverpayTx = async () => {
      loading.value = true
      ansAddress.value = ''
      if (everHash.value) {
        if (!tokens.value.length) {
          try {
            await store.dispatch('updateTokenListAsync')
          } catch {
          }
        }

        try {
          const result = await getTxByEverHash(everHash.value) as any
          result.timestampFormat = dayjs(result.timestamp * 1000 || +result.nonce).format('YYYY-MM-DD HH:mm:ss')
          const token = formatTokenTag(result, tokens.value)
          result.amountFormat = fromDecimalToUnit(result.amount, token?.decimals || 18)
          result.feeFormat = fromDecimalToUnit(result.fee, token?.decimals || 18)
          result.targetChainType = formatTargetChainType(result)
          result.burnToUrl = result.action === 'burn'
            ? getExplorerUrl({
              type: 'address',
              value: result.to
            }, result.targetChainType)
            : ''
          result.targetChainTxHashUrl = result.targetChainTxHash
            ? getExplorerUrl({
              type: 'tx',
              symbol: token?.symbol ?? '',
              value: result.targetChainType === ChainType.psntest ? result.everHash : result.targetChainTxHash
            }, result.targetChainType)
            : ''
          result.internalTxs = result.action === 'bundle' ? getInternalRecordsFromBundleTx(tokens.value, result) : []
          if (isArweaveAddress(result.to)) {
            ansLoading.value = true
            getAnsResolver(result.to).then((res) => {
              ansAddress.value = res?.domain ? res.domain : ''
              ansLoading.value = false
            }).catch(() => {
              ansLoading.value = false
            })
          }
          try {
            const dataObj = JSON.parse(result.data)
            result.pubName = result.action === 'setAcc' ? dataObj.pubName : ''
            result.oprationType = dataObj.op || 'addOwner'
          } catch {}
          everpayTx.value = result
          isInternalSuccess.value = JSON.parse(everpayTx.value.internalStatus).status === 'success'
          notFound.value = false
        } catch (e: any) {
          handleErrorMsg(e, t)
          notFound.value = true
        }
        // 拥有 Tx 数据 且 rollup tx 已存在
        if (everpayTx.value && everpayTx.value.id && everpayTx.value?.targetChainType) {
          try {
            const data = await getManualSubmitData(everpayTx.value.targetChainType, everHash.value)
            manualSubmitData.value = data
            updateWalletInfo()
          } catch (e) {
            console.log(e)
            manualSubmitData.value = null
          }
        }
      } else {
        notFound.value = true
      }
      loading.value = false
    }
    const txCopyClass = [
      '.clipboard-everHash',
      '.clipboard-rolluptx',
      '.clipboard-arId',
      '.clipboard-from',
      '.clipboard-to',
      '.clipboard-chainTxHash'
    ]
    const txClipboard = () => {
      txCopyClass.forEach(item => {
        new ClipboardJS(item).on('success', function (e:any) {
          e.clearSelection()
          ElMessage({
            showClose: true,
            message: t('copy_success'),
            type: 'success',
            duration: 2000
          })
        })
      })
    }
    // 连接钱包
    const connectwallet = async () => {
      connectLoading.value = true
      try {
        await connectMetamask(manualSubmitData.value)
        ElMessage({
          showClose: true,
          message: t('connect_success'),
          type: 'success',
          duration: 2000
        })
      } catch (e:any) {
        handleErrorMsg(e, t)
      } finally {
        connectLoading.value = false
      }
    }
    // 手动提交 数据
    const manualSubmission = async () => {
      submitLoading.value = true
      let transactionResponse = null
      try {
        const result = await sendTransaction(manualSubmitData.value)
        transactionResponse = result
        if (result.hash) {
          ElMessage({
            showClose: true,
            message: t('successfully_submitted'),
            type: 'success',
            duration: 2000
          })
          // 将 everhash 和 tx hash 提交给后端
          await submitManualHash(everHash.value, result.hash)
          const token = formatTokenTag(everpayTx.value, tokens.value)
          everpayTx.value.targetChainTxHash = result.hash
          everpayTx.value.targetChainTxHashUrl = getExplorerUrl({
            type: 'tx',
            symbol: token?.symbol ?? '',
            value: result.hash
          }, everpayTx.value.targetChainType)
          submitSuccess.value = true
        }
      } catch (e) {
        console.log(e)
        ElMessage({
          showClose: true,
          message: t('failed_submitted'),
          type: 'error',
          duration: 2000
        })
        submitSuccess.value = false
      } finally {
        submitLoading.value = false
      }
      try {
        // wait 判断 交易成功
        if (transactionResponse) {
          await transactionResponse.wait()
          ElMessage({
            showClose: true,
            message: t('successful_transaction'),
            type: 'success',
            duration: 2000
          })
        }
      } catch (e) {
        submitSuccess.value = false
      }
    }
    onMounted(() => {
      updateEverpayTx()
      txClipboard()
      if (window.ethereum) {
        // 绑定监听 account 和 chainType
        window.ethereum.on('accountsChanged', (accounts: string[]) => {
          updateWalletInfo()
        })
        window.ethereum.on('chainChanged', (chainId: any) => {
          updateWalletInfo()
        })
      }
    })
    onActivated(() => {
      manualSubmitData.value = null
      submitSuccess.value = false
    })
    watch(everHash, updateEverpayTx)
    return {
      t,
      everHash,
      notFound,
      loading,
      everpayTx,
      formatMoney,
      isInternalSuccess,
      capitalize,
      ansAddress,
      ansLoading,
      manualSubmitData,
      connectwallet,
      manualSubmitVisible,
      walletInfo,
      connectLoading,
      submitLoading,
      manualSubmission,
      submitSuccess
    }
  }
})
</script>
<style></style>
