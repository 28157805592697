
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  },
  setup () {

  }
})
