
import { watch, ref, Ref, computed, onMounted, defineComponent, reactive, onActivated } from 'vue'
import { useRoute } from 'vue-router'
import LabelItem from '@/components/LabelItem.vue'
import InternalItem from '@/components/InternalItem.vue'
import TxLine from '@/components/TxLine.vue'
import dayjs from 'dayjs'
import { getExplorerUrl, getInternalRecordsFromBundleTx, formatMoney, formatTargetChainType, formatTokenTag, handleErrorMsg, connectMetamask, sendTransaction } from '@/libs/utils'
import { fromDecimalToUnit } from '@/libs/everpay-js/utils/util'
import { useStore } from '@/store'
import { getTxByEverHash, getAnsResolver, getManualSubmitData, submitManualHash } from '@/libs/api'
import NotFound from '@/components/common/NotFound.vue'
import Loading from '@/components/common/Loading.vue'
import { useI18n } from 'vue-i18n'
import ClipboardJS from 'clipboard'
import { ElMessage } from 'element-plus'
import Search from '@/components/Search.vue'
import capitalize from 'lodash/capitalize'
import isString from 'lodash/isString'
import ChainTypeLogo from '@/components/ChainTypeLogo.vue'
import MoreInfo from '@/components/common/MoreInfo.vue'
import { ChainType } from '../libs/everpay-js/types'
import { ManualSubmitData } from '../libs/types'
import { checkNetwork } from '../libs/chainLibAdaptor/ethereum'
import { Web3Provider } from '@ethersproject/providers'
export default defineComponent({
  components: {
    LabelItem,
    InternalItem,
    TxLine,
    NotFound,
    Loading,
    Search,
    ChainTypeLogo,
    MoreInfo
  },
  setup () {
    const route = useRoute()
    const notFound = ref(false)
    const loading = ref(true)
    const store = useStore()
    const { t } = useI18n()
    const tokens = computed(() => store.state.tokenList)
    const manualBurnTokens = computed(() => store.state.manualBurnTokens)
    const connectLoading = ref(false)
    const submitLoading = ref(false)
    const submitSuccess = ref(false)
    const walletInfo = reactive({
      account: '',
      accChainType: '',
      isNetworkMatch: false
    })
    const everHash = computed(() => {
      return route.params.everHash as string
    })
    const ansLoading = ref(false)
    const ansAddress = ref('')
    const everpayTx: Ref<any> = ref(null as any)
    // 手动提交数据
    const manualSubmitData = ref<ManualSubmitData | null>(null)
    // 指定 burn 交易 targetChainTxHash 为空 和 指定代币时才会出现
    const manualSubmitVisible = computed(() => {
      if (everpayTx.value && manualSubmitData.value) {
        const { action, targetChainTxHash } = everpayTx.value
        const token = formatTokenTag(everpayTx.value, tokens.value)
        return action === 'burn' && targetChainTxHash === '' && manualBurnTokens.value.find((t) => t.tokenTag === token?.tag)
      }
      return false
    })
    const isInternalSuccess = ref(true)
    const isArweaveAddress = (address: string): boolean => {
      return isString(address) && address.length === 43 && address.search(/[a-z0-9A-Z_-]{43}/g) === 0
    }
    // 更新 account 检查 chainType 是否一致
    const updateWalletInfo = async () => {
      if (window.ethereum) {
        walletInfo.account = window.ethereum ? window.ethereum.selectedAddress : ''
        walletInfo.accChainType = window.ethereum ? window.ethereum.chainId : ''
        const provider = new Web3Provider(window.ethereum)
        const network = await provider.getNetwork()
        try {
          checkNetwork(network.chainId, manualSubmitData.value?.chainType as ChainType)
          walletInfo.isNetworkMatch = true
        } catch (e) {
          console.log(e)
          walletInfo.isNetworkMatch = false
        }
      }
    }
    const updateEverpayTx = async () => {
      loading.value = true
      ansAddress.value = ''
      if (everHash.value) {
        if (!tokens.value.length) {
          try {
            await store.dispatch('updateTokenListAsync')
          } catch {
          }
        }

        try {
          const result = await getTxByEverHash(everHash.value) as any
          result.timestampFormat = dayjs(result.timestamp * 1000 || +result.nonce).format('YYYY-MM-DD HH:mm:ss')
          const token = formatTokenTag(result, tokens.value)
          result.amountFormat = fromDecimalToUnit(result.amount, token?.decimals || 18)
          result.feeFormat = fromDecimalToUnit(result.fee, token?.decimals || 18)
          result.targetChainType = formatTargetChainType(result)
          result.burnToUrl = result.action === 'burn'
            ? getExplorerUrl({
              type: 'address',
              value: result.to
            }, result.targetChainType)
            : ''
          result.targetChainTxHashUrl = result.targetChainTxHash
            ? getExplorerUrl({
              type: 'tx',
              symbol: token?.symbol ?? '',
              value: result.targetChainType === ChainType.psntest ? result.everHash : result.targetChainTxHash
            }, result.targetChainType)
            : ''
          result.internalTxs = result.action === 'bundle' ? getInternalRecordsFromBundleTx(tokens.value, result) : []
          if (isArweaveAddress(result.to)) {
            ansLoading.value = true
            getAnsResolver(result.to).then((res) => {
              ansAddress.value = res?.domain ? res.domain : ''
              ansLoading.value = false
            }).catch(() => {
              ansLoading.value = false
            })
          }
          try {
            const dataObj = JSON.parse(result.data)
            result.pubName = result.action === 'setAcc' ? dataObj.pubName : ''
            result.oprationType = dataObj.op || 'addOwner'
          } catch {}
          everpayTx.value = result
          isInternalSuccess.value = JSON.parse(everpayTx.value.internalStatus).status === 'success'
          notFound.value = false
        } catch (e: any) {
          handleErrorMsg(e, t)
          notFound.value = true
        }
        // 拥有 Tx 数据 且 rollup tx 已存在
        if (everpayTx.value && everpayTx.value.id && everpayTx.value?.targetChainType) {
          try {
            const data = await getManualSubmitData(everpayTx.value.targetChainType, everHash.value)
            manualSubmitData.value = data
            updateWalletInfo()
          } catch (e) {
            console.log(e)
            manualSubmitData.value = null
          }
        }
      } else {
        notFound.value = true
      }
      loading.value = false
    }
    const txCopyClass = [
      '.clipboard-everHash',
      '.clipboard-rolluptx',
      '.clipboard-arId',
      '.clipboard-from',
      '.clipboard-to',
      '.clipboard-chainTxHash'
    ]
    const txClipboard = () => {
      txCopyClass.forEach(item => {
        new ClipboardJS(item).on('success', function (e:any) {
          e.clearSelection()
          ElMessage({
            showClose: true,
            message: t('copy_success'),
            type: 'success',
            duration: 2000
          })
        })
      })
    }
    // 连接钱包
    const connectwallet = async () => {
      connectLoading.value = true
      try {
        await connectMetamask(manualSubmitData.value)
        ElMessage({
          showClose: true,
          message: t('connect_success'),
          type: 'success',
          duration: 2000
        })
      } catch (e:any) {
        handleErrorMsg(e, t)
      } finally {
        connectLoading.value = false
      }
    }
    // 手动提交 数据
    const manualSubmission = async () => {
      submitLoading.value = true
      let transactionResponse = null
      try {
        const result = await sendTransaction(manualSubmitData.value)
        transactionResponse = result
        if (result.hash) {
          ElMessage({
            showClose: true,
            message: t('successfully_submitted'),
            type: 'success',
            duration: 2000
          })
          // 将 everhash 和 tx hash 提交给后端
          await submitManualHash(everHash.value, result.hash)
          const token = formatTokenTag(everpayTx.value, tokens.value)
          everpayTx.value.targetChainTxHash = result.hash
          everpayTx.value.targetChainTxHashUrl = getExplorerUrl({
            type: 'tx',
            symbol: token?.symbol ?? '',
            value: result.hash
          }, everpayTx.value.targetChainType)
          submitSuccess.value = true
        }
      } catch (e) {
        console.log(e)
        ElMessage({
          showClose: true,
          message: t('failed_submitted'),
          type: 'error',
          duration: 2000
        })
        submitSuccess.value = false
      } finally {
        submitLoading.value = false
      }
      try {
        // wait 判断 交易成功
        if (transactionResponse) {
          await transactionResponse.wait()
          ElMessage({
            showClose: true,
            message: t('successful_transaction'),
            type: 'success',
            duration: 2000
          })
        }
      } catch (e) {
        submitSuccess.value = false
      }
    }
    onMounted(() => {
      updateEverpayTx()
      txClipboard()
      if (window.ethereum) {
        // 绑定监听 account 和 chainType
        window.ethereum.on('accountsChanged', (accounts: string[]) => {
          updateWalletInfo()
        })
        window.ethereum.on('chainChanged', (chainId: any) => {
          updateWalletInfo()
        })
      }
    })
    onActivated(() => {
      manualSubmitData.value = null
      submitSuccess.value = false
    })
    watch(everHash, updateEverpayTx)
    return {
      t,
      everHash,
      notFound,
      loading,
      everpayTx,
      formatMoney,
      isInternalSuccess,
      capitalize,
      ansAddress,
      ansLoading,
      manualSubmitData,
      connectwallet,
      manualSubmitVisible,
      walletInfo,
      connectLoading,
      submitLoading,
      manualSubmission,
      submitSuccess
    }
  }
})
