<script>
import { defineComponent } from 'vue'
import Search from '../Search.vue'
export default defineComponent({
  components: {
    Search
  },
  props: {
    type: {
      type: String,
      default: 'tx' // account
    },
    value: {
      type: String,
      default: ''
    }
  },
  setup () {

  }
})
</script>

<template>
  <div class="bg-scanMain pt-20 pb-12 lg:pt-28 flex flex-col items-center">
    <Search class="w-full flex justify-center sm:px-0 lg:hidden px-6" />
    <div class="flex flex-col items-center justify-center pt-24 pb-32 px-4 text-center text-sm" style="color: #9895B1;">
      <img class="w-28 h-28 mb-4" :src="require('../../images/404.svg')">
      <div>
        Oops！An invalid {{ type }} has been entered：
      </div>
      <div class="break-all">
        {{ value }}
      </div>
    </div>
  </div>
</template>
