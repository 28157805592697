<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  },
  setup () {

  }
})
</script>

<template>
  <div
    class="flex flex-row items-start text-sm py-6 "
    :class="noBorder ? '' : 'border-b sm:border-0 border-dashed' "
    style="color:rgba(13, 13, 13, 0.85)">
    <div class="w-120px sm:w-56 mb-1 md:mb-0" :class="labelClass">
      {{ label }}
    </div>
    <slot />
  </div>
</template>

<style scoped>

</style>
