<script lang="ts">
import { defineComponent } from 'vue'
import { formatMoney } from '@/libs/utils'
export default defineComponent({
  props: {
    from: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    },
    amount: {
      type: String,
      default: ''
    },
    symbol: {
      type: String,
      default: ''
    }
  },
  setup () {
    const formatInfo = (info: string) => {
      return info.slice(0, 16) + '...'
    }
    return {
      formatMoney,
      formatInfo
    }
  }
})
</script>

<template>
  <div class="flex flex-col sm:flex-row items-start flex-wrap justify-start sm:items-center mb-6 last:mb-0">
    <div class="flex sm:flex-row flex-col break-all mr-2">
      <router-link class="text-everPurple10 sm:w-32 md:w-48 " :to="`/account/${from}`">
        {{ from }}
      </router-link>
      <img class="w-4 h-4 my-2 sm:mx-4 self-center transform rotate-90 sm:rotate-0" :src="require('../images/arrow-right.svg')">
      <router-link class="text-everPurple10 sm:w-32 md:w-48" :to="`/account/${to}`">
        {{ to }}
      </router-link>
    </div>
    <div class="p-1 mt-2 sm:mt-0 bg-scanAmountbg rounded-md">
      <span class="break-all">{{ formatMoney(amount) }}</span> <span>{{ symbol }}</span>
    </div>
  </div>
</template>
